/* *{
    outline: 1px solid green;
} */

svg{
    max-width:100% !important;
}



@media (max-width: 900px) {
    * :not(h1, h2, h3,h4,h5,h6){
        font-size: 0.9rem !important;
    }
    
    button:not(:empty) {
        font-size: 0.9rem !important;
    }
    
  }


  @media (max-width: 360px) {
    * :not(h1, h2, h3,h4,h5,h6){
        font-size: 0.8rem !important;
    }
    
    button:not(:empty) {
        font-size: 0.8rem !important;
    }
  }