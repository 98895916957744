/*.content {
  padding-top: 16px;
}*/
.gmap {
  border: none;
}

svg{
  max-width:100% !important;
}

/* .customMenuItem{
  text-decoration: none;
  display: block;
  height: 64px;
} */


.errorMessage{
  font-size: 32px;
  color:#3f3d56
}


.customMenuItem{
  display: inline-block !important;
  height: 74px !important;
  width: 74px !important;
  margin: 2px 5px !important;
  padding: 10px 5px !important;
  border-radius: 5px !important;
  font-size: 15px !important;
  text-align: center !important;
  box-sizing: content-box !important;
}

.customMenuItemContent{
  display:block !important;
  text-decoration: none;
  height: 74px !important;
}

.customMenuItemContent p{
    color:#757575 !important;
    font-weight: 500 !important;
    font-family: 'CircularProBook' !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #949494;
  border-radius:5px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #949494;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.notValidLicense{
  filter: grayscale(140%) brightness(80%) !important;
}


a.accountmenuCss {
  text-decoration:none !important;
  color:rgba(0, 0, 0, 0.87) !important;
}

.introjs-tooltip-header{
  display: none !important;

}
.myTooltipClass{
  background-color: #B9F6CA !important;
  display: flex !important;
  flex-direction: column !important;
  border-radius: 15px !important;

}
.introjs-tooltiptext{
  font-family: 'CircularProBook' !important;
}

.firstTip{
  max-width: 328px !important ;
  width: 328px;
  background-color: #B9F6CA !important;
  display: flex !important;
  flex-direction: column !important;
  border-radius: 15px !important;
}


.introjs-helperNumberLayer{
  order: -1 !important;
  margin: 0 !important;
  padding: 24px !important;
  text-align: left !important;
  font-family: 'CircularProMedium' !important;
  color: black !important;
}

.introjs-arrow.left{
  border-right-color:#B9F6CA !important;
  left: -9px !important;
}


 .introjs-arrow.top{
  border-bottom-color:#B9F6CA !important;
  top: -9px !important;
 }
.introjs-prevbutton , .introjs-nextbutton{
  border: none !important;
  background: transparent !important;
  text-transform: uppercase !important;
  text-shadow: none !important;
  font-size: 14px !important;
  font-family: 'CircularProBold' !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;

}


.introjs-prevbutton:hover , .introjs-nextbutton:hover{
  border: none !important;
  outline: none !important;
}
.introjs-prevbutton:active , .introjs-nextbutton:active{
  border: none !important;
  outline: none !important;
}
.introjs-tooltipbuttons{
  border: none !important;
  display: flex !important;
  justify-content: flex-end;
}

.introjs-prevbutton {
  color: #757575 !important ;

}

.introjs-nextbutton{
  color:#16A100  !important;
}


.myHighlightClass{
  border:  none !important;
  border-radius:20px !important ;
  box-shadow: rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;
}


.smallHiglight{
  margin-left: 10px !important;
  border:  none !important;

  border-radius:20px !important ;
  box-shadow: rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;
  width: 70px !important;
  height: 65px !important;
}


#onBoarding{
  backdrop-filter: blur(20px);
}

.leaflet-container{
  height: 100%;
  width: 100%;
}


.leaflet-tooltip-pane > *{

  border-radius: 8px !important;
  font-size: 16px;
  position: absolute !important;
  bottom: -25px !important;
  opacity: 1 !important;
  padding-inline: 8px !important;
  
}